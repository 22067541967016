import { Injectable } from '@angular/core';
import { DocumentSagaState } from '@core/core.types';
import { DocumentsService } from '@core/services/documents.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isEqual } from 'lodash';
import { interval, switchMap, takeWhile, tap } from 'rxjs';
import {
  CheckApprovalState,
  ConfirmDocumentApproval,
  MassApproveDocuments,
  SAGA_STATE_NAME,
} from './document-approval-saga.actions';

export interface SagaStateModel {
  activeSagas: { [key: string]: { state: DocumentSagaState; id: string } };
}

@State<SagaStateModel>({
  name: SAGA_STATE_NAME,
  defaults: {
    activeSagas: {},
  },
})
@Injectable()
export class DocumentApprovalSagaState {
  constructor(private documentsService: DocumentsService) {}

  @Selector()
  static getSagaById({ activeSagas }: SagaStateModel) {
    return (sagaId: string) => activeSagas[sagaId];
  }

  @Selector()
  static isSagaInProgress({ activeSagas }: SagaStateModel) {
    return (sagaId: string) => activeSagas[sagaId].state.inProgress;
  }

  @Selector()
  static isSagaReadyForApproval({ activeSagas }: SagaStateModel) {
    return (sagaId: string) =>
      !activeSagas[sagaId].state.inProgress &&
      (activeSagas[sagaId].state.isVerificationReady ||
        !!activeSagas[sagaId].state.failed);
  }

  @Selector()
  static hasSagaErrors({ activeSagas }: SagaStateModel) {
    return (sagaId: string) =>
      activeSagas[sagaId].state.warnings?.length ||
      activeSagas[sagaId].state.errors?.length;
  }

  @Action(MassApproveDocuments)
  massApproveDocuments(
    { patchState, getState, dispatch }: StateContext<SagaStateModel>,
    { documentIds, sagaId }: MassApproveDocuments
  ) {
    return this.documentsService.massApproveDocuments(documentIds).pipe(
      tap((batch: { id: string }) =>
        patchState({
          activeSagas: {
            ...getState().activeSagas,
            [sagaId]: { state: null, id: batch.id },
          },
        })
      ),
      switchMap(() =>
        interval(500).pipe(
          switchMap(() => dispatch(new CheckApprovalState(sagaId))),
          takeWhile(() => getState().activeSagas[sagaId].state.inProgress)
        )
      )
    );
  }

  @Action(CheckApprovalState)
  checkApprovalState(
    { patchState, getState }: StateContext<SagaStateModel>,
    { sagaId }: CheckApprovalState
  ) {
    const saga = getState().activeSagas[sagaId];

    return this.documentsService.checkApprovalState(saga.id).pipe(
      tap((batch: DocumentSagaState) => {
        if (!isEqual(batch, saga.state)) {
          patchState({
            activeSagas: {
              ...getState().activeSagas,
              [sagaId]: { state: batch, id: saga.id },
            },
          });
        }
      })
    );
  }

  @Action(ConfirmDocumentApproval)
  confirmDocumentApproval(
    { getState, dispatch }: StateContext<SagaStateModel>,
    { sagaId }: ConfirmDocumentApproval
  ) {
    const saga = getState().activeSagas[sagaId];

    return this.documentsService.confirmMassDocumentApproval(saga.id).pipe(
      switchMap(() =>
        interval(500).pipe(
          switchMap(() => dispatch(new CheckApprovalState(sagaId))),
          takeWhile(() => getState().activeSagas[sagaId].state.inProgress)
        )
      )
    );
  }
}
