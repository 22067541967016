import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle } from '@core/core.types';
import { environment } from '@root/environments/environment';
import { NIFTY_APPLICATIONID_KEY } from '@app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class BundleService {
  private apiUrl = `${environment.apiUrl}/bundles`;
  constructor(private http: HttpClient) {}

  getBundles() {
    return this.http.get<Bundle[]>(`${this.apiUrl}?State=All`);
  }

  getBundle(bundleId: string) {
    return this.http.get<Bundle>(`${this.apiUrl}/${bundleId}`);
  }

  createBundle(bundle: Bundle) {
    return this.http.post<{ id: string }>(this.apiUrl, {
      ...bundle,
      applicationIds: [NIFTY_APPLICATIONID_KEY],
    });
  }

  updateBundle(bundle: Bundle) {
    return this.http.put<{ id: string }>(`${this.apiUrl}/${bundle.id}`, {
      ...bundle,
      applicationIds: [NIFTY_APPLICATIONID_KEY],
    });
  }

  activateBundle(bundleId: string) {
    return this.http.patch<void>(`${this.apiUrl}/${bundleId}/activate`, {});
  }

  deactivateBundle(bundleId: string) {
    return this.http.patch<void>(`${this.apiUrl}/${bundleId}/deactivate`, {});
  }

  addProductToBundle(bundleId: string, productId: string) {
    return this.http.post<void>(
      `${this.apiUrl}/${bundleId}/products/${productId}`,
      {}
    );
  }

  removeProductFromBundle(bundleId: string, productId: string) {
    return this.http.delete<void>(
      `${this.apiUrl}/${bundleId}/products/${productId}`
    );
  }

  assignBundleUpgrade(bundleId: string, upgradeId: string) {
    return this.http.post<void>(
      `${this.apiUrl}/${bundleId}/upgrades/${upgradeId}`,
      {}
    );
  }

  unassignBundleUpgrade(bundleId: string, upgradeId: string) {
    return this.http.delete<void>(
      `${this.apiUrl}/${bundleId}/upgrades/${upgradeId}`
    );
  }
}
