export const SAGA_STATE_NAME = 'DocumentApprovalSaga';

export class MassApproveDocuments {
  static type = `[${SAGA_STATE_NAME}] MassApproveDocuments`;
  constructor(
    public documentIds: string[],
    public sagaId: string
  ) {}
}

export class CheckApprovalState {
  static type = `[${SAGA_STATE_NAME}] CheckApprovalState`;
  constructor(public sagaId: string) {}
}

export class ConfirmDocumentApproval {
  static type = `[${SAGA_STATE_NAME}] ConfirmDocumentApproval`;
  constructor(public sagaId: string) {}
}
