/* eslint-disable @typescript-eslint/naming-convention */
import { SortDirection } from '@core/core.types';
import { environment } from '@root/environments/environment';

export const DOCUMENT_DATE_FORMAT = 'dd.MM.yyyy';
export const DOCUMENT_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const DOCUMENT_DATE_TIME_SECONDS_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DOCUMENT_DATE_TIME_AT_FORMAT = 'dd.MM.yyyy "at" hh:mm:ss';
export const MOMENT_DATE_FORMAT = 'DD.MM.YYYY';
export const MOMENT_DATE_FORMAT_DASH = 'DD-MM-YYYY';
export const MOMENT_DATE_FORMAT_DASH_REVERSED = 'YYYY-MM-DD';
export const MOMENT_DATE_FORMAT_SLASH = 'DD/MM/YYYY';
export const MOMENT_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const MOMENT_DATE_TIME_FORMAT_SLASH = 'DD/MM/YYYY HH:mm';
export const MOMENT_DATE_TIME_SECONDS_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const DATEPICKER_DATE_FORMAT = 'DD[.]MM[.]YYYY';
export const END_TIME_OF_DAY = 'T23:59:59';
export const START_TIME_OF_DAY = 'T00:00:00';
export const DEFAULT_DIALOG_OPTIONS = {
  closeOnEsc: false,
  closeOnBackdropClick: false,
};
export const MAX_URL_QUERY_PARAMETERS_IDS = 200;
export const NIFTY_APPLICATIONID_KEY = '373e66a1-adf3-4819-81fd-e310a991d588';
export const PAGING_HEADERS = {
  orderBy: 'X-Order-By',
  orderDirection: 'X-Order-Direction',
  top: 'X-Paging-Top',
  skip: 'X-Paging-Skip',
  totalCount: 'X-Paging-TotalCount',
  continuationToken: 'X-Paging-Continuation',
  select: 'X-Select',
};
export const LOCAL_STORAGE_KEYS = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  language: 'language',
  runs: 'runs',
  checkedDocuments: 'checkedDocuments',
};
export const API_SPECIFICATION_CONSTANTS = {
  ask: 'ask',
  tell: 'tell',
  userAccounts: 'UserAccounts',
  applicationId: 'ApplicationId',
  searchToken: 'SearchToken',
  languages: {
    actor: 'LanguageDirectory',
    command: 'ListLanguages',
  },
  countries: {
    actor: 'CountryDirectory',
    command: 'ListCountries',
  },
};
export const AUTH_TOKEN_ROLES_KEY = 'http://schemas.nomnio.com/2017/5/roles';
export const AUTH0_CONFIG = {
  responseType: 'token token_id',
  refreshTokenGrantType: 'refresh_token',
  me: 'me',
  usernamePasswordRealm: 'Username-Password-Authentication',
  scope: 'openid offline_access',
};
export const DEFAULT_SORT_SETTINGS = {
  orderBy: 'name',
  sortDirection: SortDirection.asc,
};

export const KITS_API_CONSTANTS = {
  kits: `kits${environment.actorsPrefix}`,
  commandListKits: 'ListKits',
  commandGenerateKit: 'GenerateKit',
  commandUpdateKit: 'UpdateKit',
  commandMarkAsShipped: 'MarkAsShipped',
  commandSendKitToLaboratory: 'SendKitToLaboratory',
  commandMarkAsReturned: 'MarkAsReturned',
  commandDeleteKit: 'DeleteKit',
  commandGetKit: 'GetKit',
  commandAssignPartnerToKit: 'AssignPartnerToKit',
  commandAssignProductInventoryItem: 'AssignProductInventoryItem',
  commandUnassignProductInventoryItem: 'UnassignProductInventoryItem',
  actorKitDirectory: 'KitDirectory',
  actorKit: 'Kit',
  actorKitProjector: 'KitProjector',
};
export const COUPONS_API_CONSTANTS = {
  coupons: `coupons${environment.actorsPrefix}`,
  commandProvisionCoupon: 'ProvisionCoupon',
  commandUpdateCoupon: 'UpdateCoupon',
  commandDeleteCoupon: 'DeleteCoupon',
  commandListCoupons: 'ListCoupons',
  commandFindCoupon: 'FindCoupon',
  commandAssignCouponDomainToCoupon: 'AssignCouponDomainToCoupon',
  commandUnassignCouponDomainFromCoupon: 'UnassignCouponDomainFromCoupon',
  commandAssignProductToCoupon: 'AssignProductToCoupon',
  commandUnassignProductFromCoupon: 'UnassignProductFromCoupon',
  commandRevokeCoupon: 'RevokeCoupon',
  actorCoupon: 'Coupon',
  actorCouponManager: 'CouponManager',
  actorCouponDirectory: 'CouponDirectory',
};
export const PRODUCTS_API_CONSTANTS = {
  productcatalogue: `productcatalogue${environment.actorsPrefix}`,
  actorProductDirectory: 'ProductDirectory',
  actorProductManager: 'ProductManager',
  actorProduct: 'Product',
  actorProductProjector: 'ProductProjector',
  actorProductConfigurationDirectory: 'ProductConfigurationDirectory',
  actorProductConfigurationProjector: 'ProductConfigurationProjector',
  actorProductConfiguration: 'ProductConfiguration',
  commandListProducts: 'ListProducts',
  commadProvisionNewProduct: 'ProvisionNewProduct',
  commandProvisionProductUpdate: 'ProvisionProductUpdate',
  commandReactivateProduct: 'ReactivateProduct',
  commandDeactivateProduct: 'DeactivateProduct',
  queryGetProduct: 'GetProduct',
  commandGenerateProductConfiguration: 'GenerateProductConfiguration',
  commandDeleteProductConfiguration: 'DeleteProductConfiguration',
  commandGetProductConfiguration: 'GetProductConfiguration',
  commandListProductConfigurations: 'ListProductConfigurations',
  commandAddChildProduct: 'AddChildProduct',
  commandRemoveChildProduct: 'RemoveChildProduct',
};
export const PARTNER_ID = 'PartnerId';
export const UNASSIGNED_PARTNER_ID = '52b04bfb-38a2-49bd-9c77-2cd444517776';
export const ALL_KITS_PARTNER_ID = 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa';

export const TREATMENT_APPROVING_MANAGER_CONSTANTS = {
  actor: 'NiptTestApprovingManager',
  commandDashboardApprove: 'ApproveDashboardDocuments',
  commandApprove: 'ApproveDocument',
};

export const PARTNERS_API_CONSTANTS = {
  partners: `partners${environment.actorsPrefix}`,
  actorPartner: 'Partner',
  actorPartnerDirectory: 'PartnerDirectory',
  actorPartnerProjector: 'PartnerProjector',
  actorPartnerTypeDirectory: 'PartnerTypeDirectory',
  actorPartnerManager: 'PartnerManager',
  commandListPartners: 'ListPartners',
  commandGetPartner: 'GetPartner',
  commandProvisionPartnerUpdate: 'ProvisionPartnerUpdate',
  commandAssignProductConfigurationToPartner:
    'AssignProductConfigurationToPartner',
  commandUnassignProductConfigurationFromPartner:
    'UnassignProductConfigurationFromPartner',
  commandListPartnerTypes: 'ListPartnerTypes',
  commandProvisionPartner: 'ProvisionPartner',
  commandAssignSubPartnerToPartner: 'AssignSubPartnerToPartner',
  commandUnassignSubPartnerFromPartner: 'UnassignSubPartnerFromPartner',
  commandDeactivatePartner: 'DeactivatePartner',
  commandReactivatePartner: 'ReactivatePartner',
  commandAssignUserAccountToPartner: 'AssignUserAccountToPartner',
  commandAssignNotificationProfileToPartner:
    'AssignNotificationProfileToPartner',
  commandUnassignNotificationProfileFromPartner:
    'UnassignNotificationProfileFromPartner',
  commandAssignPaymentTypeToPartner: 'AssignPaymentTypeToPartner ',
  commandUnassignPaymentTypeFromPartner: 'UnassignPaymentTypeFromPartner ',
};
export const TREATMENTS_API_CONSTANTS = {
  nipts: `nipts${environment.actorsPrefix}`,
  actorNiptTestDirectory: 'NiptTestDirectory',
  actorNiptManagement: 'NiptManagement',
  commandListNiptTests: 'ListNiptTests',
  queryGetNiptTestStatistics: 'GetNiptTestStatistics',
  actorNiptTestManager: 'NiptTestManager',
  commandProvisionNiptTest: 'ProvisionNiptTest',
  actorNiptTest: 'NiptTest',
  commandRemovePatientFamilyDiseaseHistory: 'RemovePatientFamilyDiseaseHistory',
  commandAddPatientFamilyDiseaseHistory: 'AddPatientFamilyDiseaseHistory',
  commandAddPatientPregnancyDiseaseHistory: 'AddPatientPregnancyDiseaseHistory',
  commandRemovePatientPregnancyDiseaseHistory:
    'RemovePatientPregnancyDiseaseHistory',
  commandAddPatientCarrierOfGeneticDiseaseInformation:
    'AddPatientCarrierOfGeneticDiseaseInformation',
  commandRemovePatientCarrierOfGeneticDiseaseInformation:
    'RemovePatientCarrierOfGeneticDiseaseInformation',
  commandProvisionNiptTestUpdate: 'ProvisionNiptTestUpdate',
  commandDeleteNiptTest: 'DeleteNiptTest',
  commandDeactivateNiptTest: 'DeactivateNiptTest',
  commandReactivateNiptTest: 'ReactivateNiptTest',
  commandMarkNiptTestAsFailed: 'MarkNiptTestAsFailed',
  commandMarkNiptTestAsDelayed: 'MarkNiptTestAsDelayed',
  commandMarkNiptTestAsResample: 'MarkNiptTestAsResample',
  commandMarkNiptTestAsNoCall: 'MarkNiptTestAsNoCall',
  commandMarkNiptTestAsNotQualified: 'MarkNiptTestAsNotQualified',
  commandMarkNiptTestAsProcessed: 'MarkNiptTestAsProcessed',
  commandRevokeNiptTestProcessed: 'RevokeNiptTestProcessed',
  commandRevokeNiptTestResample: 'RevokeNiptTestResample',
  commandRevokeNiptTestNoCall: 'RevokeNiptTestNoCall',
  commandRevokeNiptTestDelayed: 'RevokeNiptTestDelayed',
  commandSetDoctorInvoiceNumber: 'SetDoctorInvoiceNumber',
  commandSetNotes: 'SetNotes',
  commandSetLaboratoryInvoiceNumber: 'SetLaboratoryInvoiceNumber',
  actorNiptTestProjector: 'NiptTestProjector',
  commandGetNiptTest: 'GetNiptTest',
  commandAssignDocumentToNiptTest: 'AssignDocumentToNiptTest',
  commandUnassignDocumentFromNiptTest: 'UnassignDocumentFromNiptTest',
  commandProvisionKitChange: 'ProvisionKitChange',
  commandMarkNiptTestsAsExported: 'MarkNiptTestsAsExported',
  commandSetGeneplanetInvoiceNumber: 'SetGeneplanetInvoiceNumber',
  commandGetNiptTestTAT: 'GetNiptTestTAT',
  commandProvisionAssignInvoiceToNiptTest: 'ProvisionAssignInvoiceToNiptTest',
  commandResultReferenceLink: 'ResultReferenceLink',
  commandProvisionMarkInvoiceAsPaid: 'ProvisionMarkInvoiceAsPaid',
  commandProvisionRevokeInvoicePaid: 'ProvisionRevokeInvoicePaid',
};
export const PRODUCT_INVENTORY_API_CONSTANTS = {
  productinventory: `productinventory${environment.actorsPrefix}`,
  actorProductInventoryItemProjector: 'ProductInventoryItemProjector',
  commandGetProductInventoryItem: 'GetProductInventoryItem',
  actorProductInventoryManager: 'ProductInventoryManager',
  commandProvisionNewProductInventoryItem: 'ProvisionNewProductInventoryItem',
  actorProductInventoryItemDirectory: 'ProductInventoryItemDirectory',
  commandListProductInventoryItems: 'ListProductInventoryItems',
  commandDeleteProductInventoryItem: 'DeleteProductInventoryItem',
  commandUpdateProductInventoryItem: 'UpdateProductInventoryItem',
  actorProductInventoryItem: 'ProductInventoryItem',
};
export const DOCUMENTS_API_CONSTANTS = {
  actorNiptTest: 'NiptTest',
  documents: `documents${environment.actorsPrefix}`,
  actorDocument: 'Document',
  commandGenerateDocument: 'GenerateDocument',
  commandArchiveDocument: 'ArchiveDocument',
  commandApproveDocument: 'MarkDocumentAsApproved',
  commandMarkDocumentAsArchived: 'MarkDocumentAsArchived',
  commandDisapproveDocument: 'DisapproveDocument',
  commandDeleteDocument: 'DeleteDocument',
  actorDocumentProjector: 'DocumentProjector',
  actorDocumentDirectory: 'DocumentDirectory',
  commandGetDocument: 'GetDocument',
  queryListDocuments: 'ListDocuments',
  deleteNiptTestDocument: 'DeleteNiptTestDocument',
};
export const PARTNERS_STATE_NAME = 'partners';
export const SUBPARTNERS_STATE_NAME = 'subPartners';

export const FIRST_LEVEL_PARTNER_TYPES = {
  public: 'Public',
  publicHospital: 'Public Hospital',
  private: 'Private',
  privateClinic: 'Private Clinic',
  laboratory: 'Laboratory',
  referral: 'Referral',
};

export const DOCTORS_PARTNER_TYPES = {
  business: 'Business',
  insurance: 'Insurance',
  physician: 'Physician',
  medicalAdministrator: 'Medical Administrator',
  collectionPoint: 'Collection Point',
  referringPhysician: 'Referring Physician',
};

export const ACCOUNT_MANAGERS_API_CONSTANTS = {
  queryListAccountManager: 'ListAccountManagers',
  accountManagerDirectory: 'AccountManagerDirectory',
};

export const Language = {
  default: 'en',
};

export const EXPORTERS_API_CONSTANTS = {
  actorRunsExporter: 'RunsExporter',
  actorKitsExporter: 'NiptKitExporter',
  actorPartnersExporters: 'NiptPartnersExporter',
  actorNiptsExporter: 'NiptsExporter',
  actorBlobUriGenerator: 'BlobUriGenerator',
  queryGetCreateBlobUri: 'GetCreateBlobUri',
  queryGetReadBlobUri: 'GetReadBlobUri',
  queryGetState: 'QuerySaga',
  commandStatus: 'status',
  commandPlasmaExport: 'ExportPlasmaInformation',
  commandBgiPlasmaExport: 'ExportBgiPlasmaInformation',
  commandHalosExport: 'ExportHalosInformation',
  commandExportInPartnerWarehouseKits: 'ExportInPartnerWarehouseKits',
  commandExportDoctors: 'ExportDoctors',
  commandExportPartners: 'ExportNiptPartners',
  commandExportOnlinePartners: 'ExportOnlinePartners',
  commandExportDoctorsByPartners: 'ExportDoctorsByPartners',
  commandExportNipts: 'ExportNipts',
  commandExportNiptsFull: 'ExportNiptsFull',
  commandExportInformationSheet: 'ExportInformationSheet',
  commandExportBgiInformationSheet: 'ExportBgiInformationSheet',
  commandNiptsInvoiceExport: 'ExportNiptsForInvoice',
  commandNiptsAnalyticsExport: 'ExportNiptsForAnalytics',
  commandInsuranceReportExport: 'ExportNiptsForInsurance',
  commandNiptsReportExport: 'ExportNiptsForReport',
  commandN2DReportExport: 'ExportN2DStatistics',
  commandN2DCustomerOrdersExport: 'ExportN2DCustomerOrders',
  commandLogisticsReportExport: 'ExportLogistics',
  commandOrdersReportExport: 'ExportNiptOrders',
  commandConsentsReportExport: 'ExportConsents',
  commandOperationsReportExport: 'ExportOperations',
};

export const EMAILS_API_CONSTANTS = {
  actorEmail: 'Email',
  commandSendEmail: 'SendEmail',
  commandSendExtendedEmali: 'SendExtendedEmail',
};

export const NOTIFICATION_PROFILES_API_CONSTANTS = {
  notificationprofiles: `notificationprofiles${environment.actorsPrefix}`,
  actorNotificationProfile: 'NotificationProfile',
  actorNotificationProfileProjector: 'NotificationProfileProjector',
  actorNotificationProfileDirectory: 'NotificationProfileDirectory',
  queryGetNotificationProfile: 'GetNotificationProfile',
  commandGenerateNotificationProfile: 'GenerateNotificationProfile',
  commandUpdateNotificatioNProfile: 'UpdateNotificationProfile',
  commandDeleteNotificationProfile: 'DeleteNotificationProfile',
  commandListNotificationProfiles: 'ListNotificationProfiles',
};

export const INVOICES_API_CONSTANTS = {
  invoice: 'Invoice',
  invoices: `invoices${environment.actorsPrefix}`,
  actorInvoiceDirectory: 'InvoiceDirectory',
  actorInvoiceProjector: 'InvoiceProjector',
  actorInvoicePaymentDirectory: 'InvoicePaymentDirectory',
  actorInvoicePayment: 'InvoicePayment',
  commandGetInvoice: 'GetInvoice',
  commandGenerateInvoice: 'GenerateInvoice',
  commandListInvoices: 'ListInvoices',
  commandListInvoicePayments: 'ListInvoicePayments',
  commandUpdateInvoice: 'UpdateInvoice',
  commandMarkInvoicePaymentAsPaid: 'MarkInvoicePaymentAsPaid',
  commandGenerateInvoicePayment: 'GenerateInvoicePayment',
  commandAssignInvoicePaymentToInvoice: 'AssignInvoicePaymentToInvoice',
  commandUnassignInvoicePaymentFromInvoice: 'UnassignInvoicePaymentFromInvoice',
  commandDeleteInvoicePayment: 'DeleteInvoicePayment',
  commandUpdateInvoicePayment: 'UpdateInvoicePayment',
  commandSetInvoiceLaboratoryPrice: 'SetInvoiceLaboratoryPrice',
  commandSetInvoiceDiscount: 'SetInvoiceDiscount',
  commandMarkAsPaid: 'MarkInvoiceAsPaid',
  commandRevokePaid: 'RevokeInvoicePaid',
};
export const SIGNEE_API_CONSTANTS = {
  niptSigneeDirectory: 'NIPTSigneeDirectory',
  commandListNIPTSignees: 'ListNIPTSignees',
};

export const languages = [
  {
    id: '694bb4e0-0391-9f05-63ae-7f6215d15ae5',
    name: 'Spanish',
    isoCode: 'es-ES',
  },
  {
    id: '2446bd8c-9343-4597-b274-324e9d8d5a4b',
    name: 'Latvian',
    isoCode: 'lv-LV',
  },
  {
    id: 'a5641523-bf11-57cd-1a37-ef001c8ada5f',
    name: 'Polish',
    isoCode: 'pl-PL',
  },
  {
    id: '71679474-e2aa-3358-73fb-12176699c31f',
    name: 'Slovenian',
    isoCode: 'sl-SI',
  },
  {
    id: '1018efe6-0ea5-d33c-5975-c486a8b861b3',
    name: 'Chinese',
    isoCode: 'zh-CN',
  },
  {
    id: '9e04c9db-5ada-48f0-80cf-f5ca3f18cdbe',
    name: 'Greek',
    isoCode: 'gr-GR',
  },
  {
    id: 'f816b667-8a93-610f-8505-28ad37b91ee4',
    name: 'English',
    isoCode: 'en-US',
  },
  {
    id: '10aae12d-fc4f-f102-b5b9-803783e091a6',
    name: 'Albanian',
    isoCode: 'sq-AL',
  },
  {
    id: 'd991e71e-a9ca-f92d-b7c3-2db8f6948f65',
    name: 'Serbian',
    isoCode: 'sr-SP',
  },
  {
    id: '928f48e0-9308-246c-f0e3-151954073015',
    name: 'Croatian',
    isoCode: 'hr-HR',
  },
  {
    id: 'f1e0c99f-31ad-505e-be4a-b087e3462ebf',
    name: 'German',
    isoCode: 'de-DE',
  },
  {
    id: 'c2ef0e6e-3e8d-4364-8070-9320fde83ae8',
    name: 'Lithuanian',
    isoCode: 'lt-LT',
  },
  {
    id: '81fe0cca-dc74-38ec-e720-bcca6d89ad63',
    name: 'Portuguese',
    isoCode: 'pt-BR',
  },
  {
    id: '6e048e71-1b2a-4f7d-8599-7fa78fb58b8f',
    name: 'Romanian',
    isoCode: 'ro-RO',
  },
  {
    id: '264eae0a-55cf-bb0f-31fb-16fe52da06cb',
    name: 'Czech',
    isoCode: 'cs-CZ',
  },
  {
    id: '8d43c2c2-5622-49a1-b6a7-e76b118aed75',
    name: 'Ukrainian',
    isoCode: 'uk-UA',
  },
  {
    id: '1f87ecb1-7a85-4fe2-818a-a8f53815d60e',
    name: 'Estonian',
    isoCode: 'et-EE',
  },
  {
    id: '04fdabf2-bb04-7b63-be6b-eed3c99a9967',
    name: 'Italian',
    isoCode: 'it-IT',
  },
  {
    id: 'd1738146-530c-db12-63fe-d1297f036ff2',
    name: 'Slovak',
    isoCode: 'sk-SK',
  },
  {
    id: 'ac8a3330-a309-44c4-b478-6236c02ed252',
    name: 'Hungarian',
    isoCode: 'hu-HU',
  },
  {
    id: '09ee987d-e657-95ed-4eff-d79f21ba4c2c',
    name: 'Bulgarian',
    isoCode: 'bg-BG',
  },
  {
    id: '86efa080-b44d-427e-a78b-74697f643bf0',
    name: 'Macedonian',
    isoCode: 'mk-MK',
  },
];

export enum ServiceType {
  NIPT = 'NIPT',
  NIFTY = 'NIFTY',
  PRENASCAN = 'Prenascan',
  LUMIPEEK = 'Lumipeek',
  NIPTGENOMICS = 'NiptGenomics',
  MOJPRENATALNITEST = 'Moj Prenatalni Test',
}

export const HOME_ROUTES_PER_ROLE = {
  'NIPT-doctors-support': '/service-data',
  'NIPT-local': '/dashboard',
  'NIPT-customer-support': '/dashboard',
  'NIPT-lab-worker': '/dashboard',
  'NIPT-accountant-manager': '/partners',
  'NIPT-lab-operations': '/dashboard',
  'NIPT-biotech-process-manager': '/dashboard',
  'NIPT-IT-development': '/dashboard',
  'NIPT-marketing': '/orders',
};

export const HOURS_FOR_SAMPLE_TO_GET_TO_LABORATORY = 96;

export const TRANSLATION_PROJECTS = {
  delay: '5de7cb42-f76f-4b5f-84ee-78ca1637e096',
  highrisk: '89a5d299-80f1-4b67-8368-64dac7f1463e',
  insufficientblood: '1579d4ad-484f-476a-b379-926afbb5b05f',
  refundcustomerpayment: 'e1fecac5-17bb-44ef-b593-f93a62fa5efb',
  refundpartnerpayment: '92f8b3c6-ee5f-4d66-8d4b-49cf80632b49',
  resample: '6fcc79a7-e8ac-47ab-9240-528682c8c0bf',
  resampledhl: 'c4a4df9f-5b90-41c8-9680-20f01747ddfa',
  resultsready: 'af3b7ebe-40d4-4147-b1a4-c8d894cd472b',
  officialreport: 'af3b7ebe-40d4-4147-b1a4-c8d894cd472b',
  notqualified: 'd54ac15a-ce75-4190-8633-d50250960ee5',
  hemolysis: 'd54ac15a-ce75-4190-8633-d50250960ee5',
  damagedtube: 'ffba4259-89aa-4668-bd07-a8a2114e32e6',
  collectionpointclaim: 'eda3636774774-a622-42c4-a07c-6ff2d466b2a8',
  canceled: 'd54ac15a-ce75-4190-8633-d50250960ee5',
};

export const NOTIFICATION_EMAILS_CONSTANTS = {
  subjectTerm: 'Subject',
  bodyTerm: 'Body',
  serviceNameReplaceString: '-serviceName-',
  highRiskReasonReplaceString: '-highRiskReason-',
  barcodeReplaceString: '-barcode-',
  generalSendGridTemplateId: {
    [ServiceType.NIFTY]: 'd-73c75449cb9a4fb784637f9c82059cd0',
    [ServiceType.NIPT]: 'd-40f9a304a4784784afd51719db7d772b',
    [ServiceType.PRENASCAN]: 'd-61e728c8925141bf961f7bde78e3f3bb',
    [ServiceType.LUMIPEEK]: 'd-0d7ade4ae7824e35a9affd7f2fbf9d3f',
    [ServiceType.NIPTGENOMICS]: 'd-7696f5c864b841faa8af3df47e6fb601',
    [ServiceType.MOJPRENATALNITEST]: 'd-0b99786ddaa145708dfc2f563e4ea035',
  },

  resultReferenceLinkSl: 'd-12c9408343a24b44a9f22f6be55c75ac',
  resultReferenceLinkEn: 'd-1367868225a243d2bc0ccc1231a46c17',
  resultReferenceLinkHr: 'd-9b94ee5f9e5d4d7e9cd91b2093928653',
  resultReferenceLinkPl: 'd-9c0b6bbaeec44245b2d5d4f930f18b6f',

  customerFormInvitePl: 'd-c5baa3f5f9ed46c98e014173e058c521',
  customerFormInviteMx: 'd-812ab9cffd8044c1b0ccfa1a2f557cd7',

  resultReferenceLinkTemplates: {
    'd-12c9408343a24b44a9f22f6be55c75ac': 'SL',
    'd-1367868225a243d2bc0ccc1231a46c17': 'EN',
    'd-9b94ee5f9e5d4d7e9cd91b2093928653': 'HR',
    'd-9c0b6bbaeec44245b2d5d4f930f18b6f': 'PL',
    'd-c5baa3f5f9ed46c98e014173e058c521': 'PL',
    'd-812ab9cffd8044c1b0ccfa1a2f557cd7': 'MX',
  },
};

export const NOTIFICATION_EMAIL_SENDER = {
  [ServiceType.NIFTY]: 'nifty.test@geneplanet.com',
  [ServiceType.NIPT]: 'nipt@geneplanet.com',
  [ServiceType.PRENASCAN]: 'prenascan@geneplanet.com',
  [ServiceType.LUMIPEEK]: 'hi@lumipeek.com',
  [ServiceType.NIPTGENOMICS]: 'hi@nipt-genomics.com',
  [ServiceType.MOJPRENATALNITEST]: 'hi@moj-prenatalni-test.com',
};

export const PROPERTY_KEYS = {
  AdditionalInfo: 'AdditionalInfo',
  ExternalBloodDraw: 'ExternalBloodDraw',
  ExternalBloodDrawNoFee: 'ExternalBloodDrawNoFee',
  ExternalId: 'ExternalId',
  IsOnlinePartner: 'IsOnlinePartner',
  MinimalSupply: 'MinimalSupply',
  PlasmaLabLocation: 'PlasmaLabLocation',
  NoFee: 'NoFee',
  Rating: 'Rating',
  Region: 'Region',
  SelfBilling: 'SelfBilling',
  DocumentType: 'DocumentType',
  AnalysisType: 'AnalysisType',
};

export const INVOICE_KEYS = {
  SepaContract: 'SepaContract',
  ItemKey: 'itemKey',
  ItemValue: 'itemValue',
};

export const NAME_ANONYMIZE = {
  firstNames: {
    a: 'Anna',
    b: 'Bella',
    c: 'Claudia',
    d: 'Daniella',
    e: 'Emilie',
    f: 'Flora',
    g: 'Gabriela',
    h: 'Helen',
    i: 'Isabel',
    j: 'Jane',
    k: 'Kyla',
    l: 'Lana',
    m: 'Maria',
    n: 'Nadia',
    o: 'Olive',
    p: 'Patricia',
    q: 'Qin',
    r: 'Robin',
    s: 'Sophia',
    t: 'Tina',
    u: 'Umma',
    v: 'Vanessa',
    z: 'Zoya',
    w: 'Winny',
    x: 'Xanthia',
    y: 'Yesmina',
    default: 'Jane',
  },
  lastNames: {
    a: 'Ackerman',
    b: 'Bagley',
    c: 'Colbert',
    d: 'Dunne',
    e: 'Evans',
    f: 'Foley',
    g: 'Griffiths',
    h: 'Huffman',
    i: 'Irvin',
    j: 'Jeffers',
    k: 'Kimble',
    l: 'Lockett',
    m: 'Morris',
    n: 'Norwood',
    o: 'Owen',
    p: 'Perry',
    q: 'Quinn',
    r: 'Robinson',
    s: 'Spencer',
    t: 'Tanner',
    u: 'Underwood',
    v: 'Valadez',
    z: 'Zapata',
    w: 'Wilde',
    x: 'Xander',
    y: 'Young',
    default: 'Doe',
  },
};
