import {
  AwbAddress,
  Bundle,
  ExtendedInvoice,
  Kit,
  Partner,
  PaymentType,
  Product,
  ProductInventory,
} from '@core/core.types';
import { GeneratedReport } from '@treatments/treatment.types';

export interface ServiceData {
  id: string;
  referenceKey: string;
  partnerId: string;
  bundleIds: string[];
  sampleIds: string[];
  patientInformation: PatientInformation;
  paymentInformation: PaymentInformation;
  serviceInformation: ServiceInformation;
  notes: string;
  generated?: Date;
  incomplete?: Date;
  finished?: Date;
  status: string;
  statusChanged: Date;
  statusChangedBy: string;
  dateOfService: Date;
}

export interface PatientInformation {
  firstName: string;
  lastName: string;
  documentNumber?: string;
  dateOfBirth: Date;
  email: string;
  phoneNumber: string;
  height: number;
  weight: number;
}

export interface PaymentInformation {
  paymentType: PaymentType;
  invoiceId?: string;
  paid?: Date;
  geneplanetInvoiceNumber?: string;
  doctorInvoiceNumber?: string;
  laboratoryInvoiceNumber?: string;
  notes?: string;
}

export interface ServiceInformation {
  nipt: NiptServiceInformation;
}

export interface NiptServiceInformation {
  doctorInformation: DoctorInformation;
  patientHistory: PatientHistoryInformation;
  currentPregnancyInformation: CurrentPregnancyInformation;
  provideGenderInformation: boolean;
  incidentalFindings: boolean;
  comments: string;
}

export interface DoctorInformation {
  doctorId: string;
  doctorConsent: boolean;
}

export interface PatientHistoryInformation {
  numberOfPregnancies: number;
  numberOfBirths: number;
  cancerHistory: CancerHistory;
  albuminTherapy: TherapyInformation;
  cellularImmunotherapy: TherapyInformation;
  stemCellTherapy: TherapyInformation;
  transplantSurgery: TherapyInformation;
  bloodTransfusion: TherapyInformation;
  familyDiseaseHistory: FamilyDiseaseHistory;
  pregnancyDiseaseHistory: DiseaseHistory;
  carrierOfGeneticDiseases: DiseaseHistory;
}

export interface DiseaseHistory {
  hasHistory: boolean;
  diseases: DiseaseInformation[];
}

export interface FamilyDiseaseHistory extends DiseaseHistory {
  diseases: FamilyDiseaseInformation[];
}

export interface DiseaseInformation {
  conditionDescription: string;
}

export interface FamilyDiseaseInformation extends DiseaseInformation {
  relationToPatient: string;
}

export interface CancerHistory {
  hasHistory: boolean;
  status: CancerHistoryStatus;
}

export enum CancerHistoryStatus {
  Recovered = 'Recovered',
  CurrentlyAffected = 'CurrentlyAffected',
}

export interface TherapyInformation {
  hasReceived: boolean;
  isRelevant: boolean;
}

export interface CurrentPregnancyInformation {
  expectedConfinementDate: Date;
  gestationAge: GestationAge;
  isGestationAgeAutomaticallyCalculated: boolean;
  pregnancyType: PregnancyType;
  chorionicityType: ChorinicityType;
  inVitro: InVitro;
  priorScreeningTest: PriorScreeningTest;
  vanishingTwinSyndrome: VanishingTwinSyndrome;
  heparinTherapy: TherapyInformation;
  ultrasoundDate: Date;
  medicationDuringPregnancy: boolean;
}

export enum PregnancyType {
  singleton = 'Singleton',
  twins = 'Twins',
}

export enum ChorinicityType {
  DCDA = 'DCDA',
  MCDA = 'MCDA',
  MCMA = 'MCMA',
}

export interface PriorScreeningTest {
  priorScreeningTestType: PriorScreeningTestType;
  firstTRisks: Risks;
  secondTRisks: Risks;
}

export enum PriorScreeningTestType {
  BiochemistryFirstTrimester = 'BiochemistryFirstTrimester',
  FirstAndSecondTrimesterIntegratedTest = 'FirstAndSecondTrimesterIntegratedTest',
  NoTestTaken = 'NoTestTaken',
  Other = 'Other',
  SecondTrimester = 'SecondTrimester',
  UltrasoundBiochemistryFirstTrimester = 'UltrasoundBiochemistryFirstTrimester',
  UltrasoundFirstTrimester = 'UltrasoundFirstTrimester',
}

export interface Risks {
  t21Risk: number;
  t18Risk: number;
  t13Risk: number;
}

export interface GestationAge {
  week: number;
  day: number;
}

export interface VanishingTwinSyndrome {
  isDetected: boolean;
  detectedGestationAge: GestationAge;
}

export interface InVitro {
  isInVitroFertilized: boolean;
  vitroType: InVitroTypes;
  isSurrogateMother: boolean;
}

export enum InVitroTypes {
  OwnEgg = 'OwnEgg',
  DonorEgg = 'DonorEgg',
}

export interface ExtendedServiceData extends ServiceData {
  partner?: Partner;
  doctor?: Partner;
  bundles?: Bundle[];
  invoice?: ExtendedInvoice;
  samples?: Sample[];
}

export interface Sample {
  id: string;
  partnerId: string;
  serviceDataId: string;
  productId: string;
  kitId?: string;
  previousSampleId?: string;
  nextSampleId?: string;
  productInventoryItems?: string[];
  dateOfVenipuncture: Date;
  shippingInformation: ShippingInformation;
  serviceInformation: SampleServiceInformation;
  notes: string;
  laboratory?: string;
  generated?: Date;
  forResample?: Date;
  redrawn?: Date;
  finished?: Date;
  status?: string;
  statusTimestamp?: Date;
}

export type ProvisionSample = Omit<
  Sample,
  'id' | 'status' | 'statusTimestamp' | 'productInventoryItems'
>;

export interface ShippingInformation {
  waybill: string;
  documentId?: string;
  address?: AwbAddress;
}
export interface SampleServiceInformation {
  nipt: NiptSampleServiceInformation;
}
export interface NiptSampleServiceInformation {
  gestationAge: GestationAge;
  isGestationAgeAutomaticallyCalculated: boolean;
  priorScreeningTest: PriorScreeningTest;
  ultrasoundDate: Date;
  doctorId: string;
}

export interface ExtendedSample extends Sample {
  partner?: Partner;
  doctor?: Partner;
  product?: Product;
  serviceData?: ExtendedServiceData;
  kit: Kit;
}

export type ProvisionServiceData = Omit<
  ServiceData,
  | 'id'
  | 'sampleIds'
  | 'referenceKey'
  | 'status'
  | 'statusChanged'
  | 'statusChangedBy'
>;

export interface ExtendedProductInventoryItem extends ProductInventory {
  partner?: Partner;
  product?: Product;
  sample?: ExtendedSample;
}

export enum DocumentType {
  NoCall = 'NoCall',
  Refund = 'Refund',
  Resample = 'Resample',
  NotQualified = 'NotQualified',
  OfficialReport = 'OfficialReport',
  HighRisk = 'HighRisk',
  Delay = 'Delay',
  Canceled = 'Canceled',
}

export enum DocumentDescription {
  ByLaboratory = 'ByLaboratory',
  ByPartner = 'ByPartner',
}

export enum DocumentNotQualifiedKeywords {
  NotQualified = 'notqualified',
  NotQualifiedByPartner = 'notqualified-bypartner',
  NotQualifiedByLaboratory = 'notqualified-bylaboratory',
  Canceled = 'canceled',
}

export enum DocumentNotQualifiedType {
  None = 'None',
  Hemolysis = 'Hemolysis',
  InsufficientVolume = 'InsufficientVolume',
  TestLimitation = 'TestLimitation',
  ZGLabMistake = 'ZGLabMistake',
  KitMixup = 'KitMixup',
  NinetySixHPlus = '96h+',
  DamagedTube = 'DamagedTube',
  DHL = 'DHL',
  Other = 'Other',
  Expired = 'Expired',
}

export enum DocumentCanceledType {}

export enum ProductInventoryItemStatus {
  generated = 'Generated',
  processingStarted = 'ProcessingStarted',
  canceled = 'Canceled',
  processingFinished = 'ProcessingFinished',
  processingFailed = 'ProcessingFailed',
}

export enum SampleStatus {
  generated = 'Generated',
  forResample = 'ForResample',
  redrawn = 'Redrawn',
  finished = 'Finished',
}

export enum ServiceDataStatus {
  generated = 'Generated',
  finished = 'Finished',
  incomplete = 'Incomplete',
}

export interface ServiceDataExtensions {
  partner?: boolean;
  doctor?: boolean;
  bundles?: boolean;
  invoice?: boolean;
  samples?: boolean;
}

export interface SampleExtensions {
  partner?: boolean;
  doctor?: boolean;
  product?: boolean;
  kit?: boolean;
  serviceData?: ServiceDataExtensions;
}

export interface ProductInventoryItemExtensions {
  partner?: boolean;
  product?: boolean;
  sample?: SampleExtensions;
}

export interface DocumentExtensions {
  pii?: ProductInventoryItemExtensions;
}

export type ReportGenerationData = [
  ExtendedProductInventoryItem,
  GeneratedReport,
];

export enum ExportDialogType {
  Selected = 'Selected',
  Export = 'Export',
}

export enum ExportDialogDataType {
  Pii = 'pii',
  ServiceData = 'serviceData',
  Sample = 'sample',
}

export enum ExportType {
  Full = 'Full',
  BGIInformationSheet = 'BGIInformationSheet',
  Invoice = 'Invoice',
  Analytics = 'Analytics',
  Insurance = 'Insurance',
  Report = 'Report',
  N2DStatistics = 'N2DStatistics',
  N2DCustomerOrders = 'N2DCustomerOrders',
  Logistics = 'Logistics',
  Orders = 'Orders',
  Consents = 'Consents',
  Operations = 'Operations',
}

export enum ExportTypeNames {
  Export = 'Information sheet',
  BGIInformationSheet = 'BGI Information sheet',
  RequestForms = 'Request forms',
  BGIInformationSheetAndInformationSheet = 'BGI Information sheet + Information sheet',
  FullExport = 'Full Export',
  InvoiceExport = 'Invoice export',
  AnalyticsExport = 'Analytics export',
  ReportExport = 'Report export',
  N2DStatistics = 'N2D Statistics export',
  N2DCustomerOrders = 'N2D Customer Orders export',
  Logistics = 'Logistics export',
  Insurance = 'Insurance export',
  Orders = 'Orders',
  Consents = 'Consents',
  Operations = 'Operations export',
}

export enum ExcelFileName {
  InformationSheet = 'Information sheet',
  BGIInformationSheet = 'BGI Information sheet',
  FullInformationSheet = 'Full Information sheet',
  InvoiceSheet = 'Invoice sheet',
  AnalyticsExport = 'Analytics export',
  Insurance = 'Insurance export',
  ReportExport = 'Report export',
  N2DStatistics = 'N2D statistics export',
  N2DCustomerOrders = 'N2D customer orders export',
  Logistics = 'Logistics export',
  Orders = 'Orders export',
  Consents = 'Consents export',
  Operations = 'Operations export',
}
export interface StatusResponse {
  StatusUri: string;
  TerminateUri: string;
  InstanceId: string;
}

export enum ServiceDataTerminateReason {
  Miscarriage = 'Miscarriage',
  CanceledByPatient = 'CanceledByPatient',
  TestLimitation = 'TestLimitation',
}

export enum DataInputType {
  NIPT = 'Nipt',
  MONO = 'Mono',
}

export const DataInputTypeValidationGroups = {
  [DataInputType.NIPT]: ['niptGeneric', 'nipt'],
  [DataInputType.MONO]: ['niptGeneric', 'mono'],
};

export interface ValidationPair {
  children?: {
    [key: string]: ValidationPair;
  };
  validators?: any[];
}
