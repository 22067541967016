/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  DOCUMENTS_API_CONSTANTS,
  NIFTY_APPLICATIONID_KEY,
  PAGING_HEADERS,
} from '@app/app.constants';
import { ApiFilters, DocumentSagaState, Properties } from '@core/core.types';
import { Util } from '@core/utils/core.util';
import { HttpUtils } from '@core/utils/http-utils';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { retry } from 'rxjs/operators';
import {
  Document,
  DocumentApprovalRequest,
} from '../../treatments/treatment.types';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private readonly log = new Logger(this.constructor.name);

  private API_URL = `${environment.apiUrl}/service-data/documents`;

  private routes = {
    postGenerateDocument: (documentId: string) =>
      `${environment.apiUrl}/${DOCUMENTS_API_CONSTANTS.documents}/${DOCUMENTS_API_CONSTANTS.actorDocument}/${documentId}/${API_SPECIFICATION_CONSTANTS.tell}/${DOCUMENTS_API_CONSTANTS.commandGenerateDocument}`,
    getDocument: (documentId: string) =>
      `${environment.apiUrl}/${DOCUMENTS_API_CONSTANTS.documents}/${DOCUMENTS_API_CONSTANTS.actorDocumentProjector}/${documentId}/${API_SPECIFICATION_CONSTANTS.ask}/${DOCUMENTS_API_CONSTANTS.commandGetDocument}`,
    listDocuments: () =>
      `${environment.apiUrl}/${DOCUMENTS_API_CONSTANTS.documents}/${DOCUMENTS_API_CONSTANTS.actorDocumentDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${DOCUMENTS_API_CONSTANTS.queryListDocuments}`,
    getDocumentReadUri: (documentId: string) =>
      `${environment.apiUrl}/${DOCUMENTS_API_CONSTANTS.documents}/${DOCUMENTS_API_CONSTANTS.actorDocument}/${documentId}/${API_SPECIFICATION_CONSTANTS.ask}/GetDocumentReadUri`,
  };

  constructor(private httpClient: HttpClient) {}

  postGenerateDocument(
    extension: string,
    fileName: string,
    sizeInBytes: number,
    documentId: string = Util.CreateGuid(),
    properties: Properties[] = [],
    typeId = '188a0139-d6e5-4cde-bc68-de341d00c963',
    applicationId: string = NIFTY_APPLICATIONID_KEY
  ): Observable<any> {
    this.log.debug('post generate document');
    return this.httpClient.post(this.routes.postGenerateDocument(documentId), {
      typeId,
      fileName,
      extension,
      sizeInBytes,
      applicationId,
      properties,
    });
  }

  getDocument(documentId: string) {
    return this.httpClient.get(this.routes.getDocument(documentId));
  }

  listDocuments(
    lastStatus?: string,
    params?: ApiFilters
  ): Observable<Document[]> {
    if (params?.ids?.length === 0) {
      return of([]);
    }

    const httpParams = this.buildHttpParams(lastStatus, params);

    const requestOptions = {
      headers: new HttpHeaders({
        [PAGING_HEADERS.top]: '500',
        [PAGING_HEADERS.orderBy]: 'LastStatusChanged',
        [PAGING_HEADERS.orderDirection]: 'Desc',
      }),
      params: httpParams,
    };
    return this.httpClient.get<Document[]>(
      this.routes.listDocuments(),
      requestOptions
    );
  }

  approveDocument(documentId: string, requestBody: DocumentApprovalRequest) {
    return this.httpClient.put(
      `${this.API_URL}/${documentId}/approve`,
      requestBody ?? {}
    );
  }

  archiveDocument(documentId: string) {
    return this.httpClient.patch(
      `${environment.apiUrl}/documents/${documentId}/archive`,
      {}
    );
  }

  disapproveDocument(documentId: string) {
    return this.httpClient.put(`${this.API_URL}/${documentId}/disapprove`, {});
  }

  deleteDocument(documentId: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/documents${environment.actorsPrefix}/Document/${documentId}/tell/DeleteDocument`,
      {}
    );
  }

  getDocumentReadUri(documentId: string): Observable<string> {
    return this.httpClient
      .get<string>(this.routes.getDocumentReadUri(documentId))
      .pipe(retry(2));
  }

  getDocumentContent(documentUrl: string): Observable<Blob> {
    return this.httpClient.get(documentUrl, { responseType: 'blob' });
  }

  massApproveDocuments(documentIds: string[]) {
    return this.httpClient.post(
      `${environment.apiUrl}/service-data/documents/batch`,
      {
        documentIds,
      }
    );
  }

  checkApprovalState(id: string): Observable<DocumentSagaState> {
    return this.httpClient.get<DocumentSagaState>(
      `${environment.apiUrl}/service-data/documents/batch/${id}`
    );
  }

  confirmMassDocumentApproval(id: string) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/service-data/documents/batch/${id}/confirm`,
      {}
    );
  }

  buildHttpParams(lastStatus: string, params: ApiFilters) {
    let httpParams = HttpUtils.buildHttpParams(params)
      .set('ApplicationId', NIFTY_APPLICATIONID_KEY)
      .set('IncludeInteractions', 'true');

    if (lastStatus) {
      httpParams = httpParams.set('LastStatus', lastStatus);
    }

    return httpParams;
  }

  private setDocumentBody(documentId: string) {
    return {
      documentId,
    };
  }
}
