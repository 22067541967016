import { Injectable } from '@angular/core';
import { BundleService } from '@core/services/bundle.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { delay, map, switchMap } from 'rxjs/operators';
import { Bundle, Product, SortDirection } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { ProductsService } from '../../services/products.service';
import { CountriesState } from '../countries/countries.state';
import {
  ActivateBundleAction,
  AddChildProducts,
  AddProductAction,
  AddProductToBundleAction,
  AssignBundleUpgradeAction,
  CreateBundleAction,
  DeactivateBundleAction,
  DeactivateProductAction,
  LoadBundleAction,
  LoadBundlesAction,
  LoadProductAction,
  LoadProductsAction,
  ReactivateProductAction,
  RemoveChildProducts,
  RemoveProductFromBundleAction,
  SetBundlesAction,
  SetProductsAction,
  UnassignBundleUpgradeAction,
  UpdateBundleAction,
  UpdateBundleChildProductsAction,
  UpdateProductAction,
} from './products.actions';

const PRODUCTS_STATE_NAME = 'products';

export interface ProductsStateModel {
  productsList?: Product[];
  bundlesList?: Bundle[];
}

@State<ProductsStateModel>({
  name: PRODUCTS_STATE_NAME,
  defaults: {
    productsList: [],
    bundlesList: [],
  },
})
@Injectable()
export class ProductsListState {
  private readonly log = new Logger('ProductsListState');

  constructor(
    private productsService: ProductsService,
    private bundleService: BundleService
  ) {}

  @Selector()
  static getProductsList(state: ProductsStateModel) {
    return state.productsList;
  }

  @Selector()
  static getBundlesList(state: ProductsStateModel) {
    return state.bundlesList;
  }

  @Selector([ProductsListState.getBundlesList])
  static getActiveBundlesList(state: ProductsStateModel, bundlesList) {
    return bundlesList.filter((bundle) => !bundle.deactivated);
  }

  @Selector([ProductsListState.getBundlesList])
  static getUnactiveBundlesList(state: ProductsStateModel, bundlesList) {
    return bundlesList.filter((bundle) => bundle.deactivated);
  }

  @Selector()
  static getBundle(state: ProductsStateModel) {
    return (bundleId: string) =>
      state.bundlesList.find((b) => b.id === bundleId);
  }

  @Selector()
  static getBundles(state: ProductsStateModel) {
    return (bundleIds: string[]) =>
      state.bundlesList.filter((b) => bundleIds.includes(b.id));
  }

  @Selector([
    ProductsListState.getProductsList,
    CountriesState.getCountryByName,
  ])
  static getCountryProductList(
    state: ProductsStateModel,
    products: Product[],
    countryByName
  ) {
    return (countryName: string) => {
      const country = countryByName(countryName);
      return products.filter((product) =>
        country.productIds.includes(product.id)
      );
    };
  }

  @Selector([ProductsListState.getCountryProductList])
  static getCountryActiveProductList(
    state: ProductsStateModel,
    getCountryProductList
  ) {
    return (countryName: string) =>
      getCountryProductList(countryName).filter(
        (product) => !product.deactivated
      );
  }

  @Selector([ProductsListState.getBundlesList, CountriesState.getCountryByName])
  static getCountryBundleList(
    state: ProductsStateModel,
    bundles: Bundle[],
    countryByName
  ) {
    return (countryName: string) => {
      const country = countryByName(countryName);
      return bundles.filter((bundle: Bundle) =>
        country.bundleIds.includes(bundle.id)
      );
    };
  }

  @Selector([ProductsListState.getCountryBundleList])
  static getCountryActiveBundleList(
    state: ProductsStateModel,
    getCountryBundleList
  ) {
    return (countryName: string) =>
      getCountryBundleList(countryName).filter((bundle) => !bundle.deactivated);
  }

  @Selector([ProductsListState.getProductsList])
  static getActiveProductsList(state: ProductsStateModel, productsList) {
    return productsList.filter((product) => !product.deactivated);
  }

  @Selector([ProductsListState.getProductsList])
  static getUnactiveProductsList(state: ProductsStateModel, productsList) {
    return productsList.filter((product) => product.deactivated);
  }

  @Selector()
  static getProduct(state: ProductsStateModel) {
    return (productId: string) =>
      state.productsList.find((p) => p.id === productId);
  }

  @Selector()
  static getProductsById(state: ProductsStateModel) {
    return (productIds: string[]) =>
      state.productsList.filter((p) => productIds.includes(p.id));
  }

  @Action(SetProductsAction)
  setProducts(
    { getState, patchState }: StateContext<ProductsStateModel>,
    { products }: SetProductsAction
  ) {
    const oldProducts = getState().productsList.filter(
      (product) => !products.find((newProduct) => newProduct.id === product.id)
    );

    return patchState({
      productsList: [...oldProducts, ...products],
    });
  }

  @Action(LoadProductsAction)
  loadProductsList({ dispatch }: StateContext<ProductsStateModel>) {
    return this.productsService
      .getProducts({
        orderBy: 'name',
        orderDirection: SortDirection.asc,
      })
      .pipe(
        switchMap((values) =>
          dispatch(new SetProductsAction(values.entitiesList))
        )
      );
  }

  @Action(LoadProductAction)
  loadProduct(
    { dispatch }: StateContext<ProductsStateModel>,
    { id }: LoadProductAction
  ) {
    return this.productsService
      .getProduct(id)
      .pipe(switchMap((product) => dispatch(new SetProductsAction([product]))));
  }

  @Action(AddProductAction)
  addProduct(
    { dispatch }: StateContext<ProductsStateModel>,
    { product, reload = false }: AddProductAction
  ) {
    product.productId = product.id;
    return this.productsService.createProduct(product).pipe(
      delay(500),
      switchMap(() =>
        reload ? dispatch(new LoadProductAction(product.id)) : of(null)
      )
    );
  }

  @Action(UpdateProductAction)
  async editProduct(
    { dispatch }: StateContext<ProductsStateModel>,
    { product, reload = false }: UpdateProductAction
  ) {
    product.productId = product.id;

    return this.productsService.updateProduct(product).pipe(
      delay(500),
      switchMap(() =>
        reload ? dispatch(new LoadProductAction(product.id)) : of(null)
      )
    );
  }

  @Action(AddChildProducts)
  addChildProducts(
    { dispatch }: StateContext<ProductsStateModel>,
    { productId, childProductIds }: AddChildProducts
  ) {
    return forkJoin(
      childProductIds.map((childProductId) =>
        this.productsService.addChildProduct(productId, childProductId)
      )
    );
  }

  @Action(RemoveChildProducts)
  removeChildProducts(
    { dispatch }: StateContext<ProductsStateModel>,
    { productId, childProductIds }: RemoveChildProducts
  ) {
    return forkJoin(
      childProductIds.map((childProductId) =>
        this.productsService.removeChildProduct(productId, childProductId)
      )
    );
  }

  @Action(AssignBundleUpgradeAction)
  assignBundleUpgradeAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId, upgradeId }: AssignBundleUpgradeAction
  ) {
    return this.bundleService.assignBundleUpgrade(bundleId, upgradeId).pipe(
      delay(300),
      switchMap(() => dispatch(new LoadBundleAction(bundleId)))
    );
  }

  @Action(UnassignBundleUpgradeAction)
  unassignBundleUpgradeAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId, upgradeId }: UnassignBundleUpgradeAction
  ) {
    return this.bundleService.unassignBundleUpgrade(bundleId, upgradeId).pipe(
      delay(300),
      switchMap(() => dispatch(new LoadBundleAction(bundleId)))
    );
  }

  @Action(DeactivateProductAction)
  deactivateProduct(
    { dispatch }: StateContext<ProductsStateModel>,
    { productId }: DeactivateProductAction
  ) {
    this.log.debug('deactivateProduct');

    return this.productsService.deactivateProduct(productId).pipe(
      delay(500),
      switchMap(() => dispatch(new LoadProductAction(productId)))
    );
  }

  @Action(ReactivateProductAction)
  reactivateProduct(
    { dispatch }: StateContext<ProductsStateModel>,
    { productId }: ReactivateProductAction
  ) {
    this.log.debug('reactivateProduct');

    return this.productsService.reactivateProduct(productId).pipe(
      delay(500),
      switchMap(() => dispatch(new LoadProductAction(productId)))
    );
  }

  @Action(SetBundlesAction)
  setBundles(
    { getState, patchState }: StateContext<ProductsStateModel>,
    { bundles }: SetBundlesAction
  ) {
    const oldBundles = getState().bundlesList.filter(
      (bundle) => !bundles.find((newBundle) => newBundle.id === bundle.id)
    );

    return patchState({
      bundlesList: [...oldBundles, ...bundles],
    });
  }

  @Action(LoadBundlesAction)
  loadBundlesList(
    { getState, dispatch }: StateContext<ProductsStateModel>,
    { reload = false }: LoadBundlesAction
  ) {
    return reload || !getState().bundlesList.length
      ? this.bundleService
          .getBundles()
          .pipe(switchMap((bundles) => dispatch(new SetBundlesAction(bundles))))
      : of(null);
  }

  @Action(LoadBundleAction)
  loadBundleAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId }: LoadBundleAction
  ) {
    return this.bundleService
      .getBundle(bundleId)
      .pipe(switchMap((bundle) => dispatch(new SetBundlesAction([bundle]))));
  }

  @Action(CreateBundleAction)
  createBundleAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundle }: CreateBundleAction
  ) {
    return this.bundleService.createBundle(bundle).pipe(
      delay(200),
      switchMap((response) =>
        dispatch(
          new UpdateBundleChildProductsAction(
            response.id,
            [],
            bundle.productIds
          )
        ).pipe(map(() => response))
      ),
      delay(200),
      switchMap((response) => dispatch(new LoadBundleAction(response.id)))
    );
  }

  @Action(UpdateBundleAction)
  updateBundleAction(
    { dispatch, getState }: StateContext<ProductsStateModel>,
    { bundle }: UpdateBundleAction
  ) {
    const oldBundle = getState().bundlesList.find((b) => b.id === bundle.id);

    return this.bundleService.updateBundle(bundle).pipe(
      switchMap(() =>
        dispatch(
          new UpdateBundleChildProductsAction(
            oldBundle.id,
            oldBundle.productIds,
            bundle.productIds
          )
        )
      ),
      delay(200),
      switchMap(() => dispatch(new LoadBundleAction(oldBundle.id)))
    );
  }

  @Action(UpdateBundleChildProductsAction)
  updateBundleChildProductsAction(
    { dispatch }: StateContext<ProductsStateModel>,
    {
      bundleId,
      oldChildProductsIds,
      childProductsIds,
    }: UpdateBundleChildProductsAction
  ) {
    const removeProducts = oldChildProductsIds.filter(
      (pid) => !childProductsIds.includes(pid)
    );
    const addProducts = childProductsIds.filter(
      (pid) => !oldChildProductsIds.includes(pid)
    );
    return forkJoin([
      ...addProducts.map((productId) =>
        dispatch(new AddProductToBundleAction(bundleId, productId))
      ),
      ...removeProducts.map((productId) =>
        dispatch(new RemoveProductFromBundleAction(bundleId, productId))
      ),
    ]);
  }

  @Action(ActivateBundleAction)
  activateBundleAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId }: ActivateBundleAction
  ) {
    return this.bundleService.activateBundle(bundleId).pipe(
      delay(200),
      switchMap(() => dispatch(new LoadBundleAction(bundleId)))
    );
  }

  @Action(DeactivateBundleAction)
  deactivateBundleAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId }: DeactivateBundleAction
  ) {
    return this.bundleService.deactivateBundle(bundleId).pipe(
      delay(200),
      switchMap(() => dispatch(new LoadBundleAction(bundleId)))
    );
  }

  @Action(AddProductToBundleAction)
  addProductToBundleAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId, productId }: AddProductToBundleAction
  ) {
    return this.bundleService.addProductToBundle(bundleId, productId);
  }

  @Action(RemoveProductFromBundleAction)
  removeProductToBundleAction(
    { dispatch }: StateContext<ProductsStateModel>,
    { bundleId, productId }: RemoveProductFromBundleAction
  ) {
    return this.bundleService.removeProductFromBundle(bundleId, productId);
  }
}
